@font-face {
  font-family: openSans;
  src: url(OpenSans-Light.ttf);
}
@font-face {
  font-family: openSansBold;
  src: url(OpenSans-ExtraBold.ttf);
}
@font-face {
  font-family: loraItalic;
  src: url(Lora-Italic.ttf);
}
@font-face {
  font-family: moderna;
  src: url(MODERNA_.TTF);
}

#homeContainer {
  max-width: 1200px;
  margin: auto;
}

body{
  margin: 0;
}
#colorContainer {
  background-color: #6B7075;
}
#headerContainer {
  width: 100%;
  background-image: url(headerColor.png), url(header-img.jpg);
  background-size: cover;
  max-width: 1200px;
  margin: auto;
}
#headerHeader {
  font-family: openSansBold;
  font-size: 80px;
  text-align: center;
  color: white;
  margin: 100px 0 -20px 0;
}
#headerDesc {
  font-family: openSans;
  font-size: 20px;
  text-align: center;
  color: white;
  padding: 0 0 150px 0;
}

#navbar {
  color: white;
  font-family: openSansBold;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#navHeader {
  margin: 30px 0 0 0;
  font-size: 20px;
}
#navList {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  list-style: none;
  font-size: 12px;
  width: 35%;
  margin: 37px 0 0 0;
}

#blogList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 55%;
  list-style: none;
}
.blogPost {
  width: 100%;
}
.postTitle {
  font-family: openSansBold;
  color: #212529;
  font-size: 35px;
  margin-bottom: 15px;
}
.postSubtitle {
  font-family: openSans;
  color: #212529;
  font-size: 25px;
  margin: -10px 0 -10px 0;
}
.byline {
  font-family: loraItalic;
  color: #6c757d;
}
.darkAuthor {
  color: #212529
}
#listContainer {
  max-width: 1200px;
  margin: auto;
}

#olderPosts {
  height: 60px;
  width: 180px;
  background-color: #007189;
  border: 1px solid #006a81;
  margin: auto;
}
#olderPosts > h1 {
  font-family: openSansBold;
  font-size: 15px;
  color: white;
  text-align: center;
  margin-top: 20px;
}

#footer {
  border-top: 1px solid #212529;
  text-align: center;
  margin: 25px auto auto auto;
}
#footer > img {
  margin: 20px 10px 0 10px;
}
#footer > p {
  font-family: loraItalic;
  color: #6c757d;
  margin-bottom: 30px;
}

@media only screen and (max-width: 700px) {
  #navbar {
    display: flex;
    flex-direction: column;
  }
  #navHeader {
    margin-left: 37px;
  }
  #navList {
    width: 80%;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  #bloglist {
    margin-left: 15%;
  }

  #navigation {
    right: 0;
  }
}

#description {
  width: 80%;
  margin: 10px auto;
}

.sidenav {
  height: 100%; /* 100% Full-height */
  width: 0; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 0; /* Stay at the top */
  left: 0;
  background-color: #111; /* Black*/
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 60px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}

.sidenav a {
  margin-top: 10px;
  padding: 8px 32px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
  font-family: openSans;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.hamburger {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}

#navigation {
  position: absolute;
  z-index: 2;
  margin: 25px 25px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {font-size: 18px;}
}